<template>
  <b-container class="ml-auto mb-2" fluid>
    <div v-if="$acl.can('usersroles.roles.read')">
      <page-loader v-if="loading" type="list" :fluid="true" />
      <portal to="nav-bar-left-column">
        <page-title title="Roles" subtitle="Roles and permissions for users" />
      </portal>
      <portal to="nav-bar-right-column">
        <router-link :to="{ name: 'NewRole' }" class="btn btn-primary"><feather-icon icon="PlusIcon" class="mr-25" /> Create role</router-link>
      </portal>
      <b-card no-body class="mt-1" v-if="!selectedRole">
        <portal to="nav-bar-right-column">
          <new-role @created="onCreatedRole" />
        </portal>
        <div v-if="!loading">
          <b-table
            striped
            hover
            responsive
            class="position-relative mb-0"
            :items="roles"
            :fields="columns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :sticky-header="false"
          >
            <template #cell(name)="data">
              <b-link @click="onEditRole(data.item)">{{ data.value }}</b-link>
            </template>
            <template #cell($actions)="data">
              <!-- <b-button variant="outline-secondary" size="sm" class="btn-icon rounded-circle" @click="onEditRole(data.item.id)">
                  <feather-icon icon="Edit2Icon" />
                </b-button> -->
              <b-button variant="outline-secondary" size="sm" class="btn-icon rounded-circle" @click="onEditRole(data.item)">
                <feather-icon icon="Edit2Icon" />
              </b-button>
            </template>
          </b-table>
        </div>
      </b-card>
      <edit-role v-model="selectedRole" @cancel="refreshView" @delete="refreshView" v-else />
    </div>
    <div v-else>
      <forbidden />
    </div>
  </b-container>
</template>
<script>
import EditRole from './components/EditRole.vue'
import NewRole from './components/NewRole.vue'
import { users } from '@/api/index'

export default {
  name: 'UserRoles',
  components: { EditRole, NewRole },
  data() {
    return {
      roles: [],
      confirmDelete: false,
      selectedRole: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      loading: false,
      columns: [
        {
          key: 'name',
          label: 'Name',
          value: 'name',
          sortable: false,
        },
        {
          key: '$actions',
          label: '',
          tdClass: 'action-cell',
        },
      ],
    }
  },
  created() {
    let self = this

    self.fetchData()
  },
  methods: {
    async fetchData() {
      const self = this
      self.loading = true
      await users.getRoles(self.projectName).then(res => {
        self.roles = res.data
        self.loading = false
      })
    },
    onEditRole(role) {
      this.selectedRole = role
    },
    onCreatedRole() {
      this.fetchData()
    },
    refreshView() {
      this.selectedRole = null
      this.fetchData()
    },
  },
}
</script>
