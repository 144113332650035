<template>
  <div>
    <b-button @click="newRoleModalVisible = true" variant="primary" v-if="$acl.can('usersroles.roles.create')"
      ><feather-icon icon="PlusIcon" class="mr-25" /> Create role</b-button
    >
    <b-modal
      v-model="newRoleModalVisible"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-header
      centered
      size="sm"
      @hidden="reset"
      @ok="onSave"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Close"
    >
      <div class="px-1 pt-1">
        <b-media>
          <template #aside>
            <b-avatar size="50" alt="" class="mb-1 field-avatar">
              <feather-icon class="text-dark" icon="UserIcon" size="18" />
            </b-avatar>
          </template>
          <h4 class="media-heading">
            New Role
          </h4>
          <b-card-text>
            Create a new role to assign permissions
          </b-card-text>
        </b-media>
        <hr class="mt-0 mb-2" />
        <form-alert :errors="serverErrors" />
        <validation-observer ref="roleForm">
          <b-form v-on:keyup.enter="onSave" @submit.prevent>
            <b-row>
              <!-- names -->
              <b-col cols="12">
                <text-input v-model="role.name" placeholder="Name" label="Name" validation-rules="required" />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { users } from '@/api/index'
import DefaultNotifications from '@/components/Notification/default'
import FormComponent from '@/mixins/FormComponent'

export default {
  name: 'ProjectNewRole',
  mixins: [FormComponent],
  components: {},
  data() {
    return {
      newRoleModalVisible: false,
      role: { name: '' },
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {
    async onSave(e) {
      e.preventDefault()

      var self = this

      var validation = await self.$refs.roleForm.validateWithInfo()

      if (validation.isValid) {
        users
          .createRole(self.projectName, self.role)
          .then(res => {
            self.ResetErrors()
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.newRoleModalVisible = false
            self.$emit('created')
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.clientValidationErrors = validation
      }
    },
    reset() {
      this.newRoleModalVisible = false
      this.role = { name: '' }
    },
  },
}
</script>
