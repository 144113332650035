<template>
  <b-container class="ml-auto mb-2">
    <page-loader v-if="loading" type="content" :fluid="false" />
    <portal to="nav-bar-left-column">
      <page-title title="Users" subtitle="Edit user" />
    </portal>
    <portal to="nav-bar-right-column">
      <div class="d-flex align-items-center">
        <span v-if="hasErrors" class="mr-1 d-flex align-items-center text-danger"
          ><feather-icon icon="AlertCircleIcon" class="m-0 p-0 ml-1 mr-25" />There are errors on the page that prevent to save changes</span
        >
        <b-link size="xs" class="font-weight-normal text-secondary mr-1 font-small-2" :to="{ name: `Users` }">
          <FeatherIcon icon="CornerDownLeftIcon" class="mr-25" size="12" />
          Back to list</b-link
        >
        <b-button type="submit" :variant="saveButtonVariant" class="mr-1" @click="onSave" :disabled="!(isDirty && $acl.can('usersroles.users.update'))">
          Save
        </b-button>
        <b-dropdown variant="link" no-caret toggle-class="p-0" right>
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body" />
          </template>
          <b-dropdown-item @click="changePassword = true" v-if="$acl.can('usersroles.users.changepassword')">
            <feather-icon icon="LockIcon" class="" />
            <span class="align-middle ml-50">Change Password</span>
          </b-dropdown-item>
          <b-dropdown-item @click="confirmDelete = true" v-if="$acl.can('usersroles.users.delete')">
            <feather-icon icon="TrashIcon" class="text-danger darken-2" />
            <span class="align-middle ml-50 text-danger text-darken-2">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </portal>

    <b-tabs nav-class="mb-1" lazy v-if="!loading">
      <b-tab title="User">
        <form-alert :errors="serverErrors" />
        <fieldset :disabled="!$acl.can('usersroles.users.update')">
          <validation-observer ref="userForm">
            <b-form v-on:keyup.enter="onFormEnterKey" @submit.prevent>
              <b-card>
                <b-row>
                  <!-- names -->
                  <b-col cols="6">
                    <text-input v-model="user.firstName" placeholder="First Name" label="First Name" name="First Name" validation-rules="required" />
                  </b-col>

                  <b-col cols="6">
                    <text-input v-model="user.lastName" placeholder="Last Name" label="Last Name" name="Last Name" validation-rules="required" />
                  </b-col>

                  <!-- email -->
                  <b-col cols="6">
                    <text-input v-model="user.email" placeholder="Email" label="Email" name="Email" validation-rules="email" type="email" />
                  </b-col>

                  <!-- mobile -->
                  <b-col cols="6">
                    <text-input v-model="user.mobilePhone" placeholder="Mobile" label="Mobile Phone" name="Mobile Phone" validation-rules="" type="text" />
                  </b-col>

                  <!-- username -->
                  <b-col cols="12">
                    <text-input v-model="user.username" placeholder="Username" label="Username" name="Username" validation-rules="required" type="text" />
                  </b-col>

                  <b-col cols="6">
                    <validation-provider rules="required" name="Roles" v-slot="{ errors }" tag="div">
                      <b-form-group label="Roles" label-for="roles" :class="errors.length > 0 ? 'is-invalid' : null">
                        <v-select
                          id="roles"
                          multiple
                          :value="validUserRoles"
                          @input="onSetRoles"
                          :options="roles"
                          :reduce="role => role.id"
                          label="name"
                          v-if="$acl.can('usersroles.users.update')"
                        />
                        <div v-else>
                          <b-badge v-for="(item, index) in validUserRoles" :key="index" pill variant="primary">
                            {{ roleName(item) }}
                          </b-badge>
                        </div>
                        <!-- <v-select id="roles" multiple v-model="user.roles" :options="roles" :reduce="role => role.id" label="name" /> -->
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- status -->
                  <b-col cols="12">
                    <b-form-group>
                      <div class="inline-spacing">
                        <b-form-checkbox id="isActive" name="isActive" v-model="user.isActive">
                          Is Active
                        </b-form-checkbox>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <b-row v-if="!loading && model && model.attributes && model.attributes.length">
                <b-col cols="12" class="mb-1">
                  <h3>Profile</h3>
                </b-col>
                <b-col cols="12" v-for="field in fields" :key="field.key">
                  <field-editor
                    :field="field"
                    v-model="user.attributes[field.key]"
                    :model="model"
                    :locale="defaultLocale"
                    :read-only="!$acl.can('usersroles.users.update')"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </fieldset>
      </b-tab>
      <b-tab title="Audits">
        <audits :entity-id="user.id" entity-type="user" v-if="user.id" />
      </b-tab>
    </b-tabs>

    <b-modal id="deleteConfirmModal" hide-footer title="User" size="md" centered hide-header v-model="confirmDelete">
      <div class="d-block text-left mt-2">
        <h4>Delete User</h4>
        <p class="h6 font-weight-normal">Do you want to delete the user?</p>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="confirmDelete = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onDelete">Delete</b-button>
      </div>
    </b-modal>
    <b-modal
      id="changePasswordModal"
      hide-footer
      title="Change Password"
      size="md"
      centered
      hide-header
      v-model="changePassword"
      @hidden="onResetChangePassword"
    >
      <div class="d-block text-left mt-2">
        <form-alert :errors="changePasswordServerErrors" />
        <validation-observer ref="changePasswordForm">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <text-input
                  v-model="newPassword.password"
                  placeholder="Password"
                  label="New Password"
                  validation-rules="required"
                  type="password"
                  vid="password"
                />
              </b-col>

              <b-col cols="12">
                <text-input
                  v-model="newPassword.confirm"
                  placeholder="Confirm Password"
                  label="Confirm Password"
                  validation-rules="required|confirmpassword:password"
                  type="password"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <div class="d-flex align-items-center justify-content-start mt-4 mb-1">
        <b-button class="mr-auto" variant="outline-secondary" @click="changePassword = false">Cancel</b-button>
        <b-button class="" variant="danger" @click="onChangePassword">Submit</b-button>
      </div>
    </b-modal>
  </b-container>
</template>
<script>
import { contentTypes, users } from '@/api/index'
import vSelect from 'vue-select'
import DefaultNotifications from '@/components/Notification/default'
import { extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'
import { mapGetters } from 'vuex'
import FormComponent from '@/mixins/FormComponent'

extend('confirmpassword', {
  ...confirmed,
  message: 'The passwords do not match',
})

export default {
  name: 'ProjectEditUser',
  mixins: [FormComponent],
  components: {
    vSelect,
  },
  data() {
    return {
      user: {},
      original: {},
      roles: [],
      model: null,
      isDirty: false,
      loading: false,
      confirmDelete: false,
      changePassword: false,
      changePasswordServerErrors: [],
      newPassword: {
        password: '',
        confirm: '',
      },
    }
  },
  watch: {
    user: {
      deep: true,
      handler(newValue) {
        this.isDirty = !this.$_.isEqual(newValue, this.original)
      },
    },
  },
  computed: {
    ...mapGetters(['Settings']),
    id() {
      return this.$route.params.id
    },
    validUserRoles() {
      const self = this
      const roles = self.roles.map(r => r.id)
      const validRoles = self.$_.filter(self.user.roles, function(item) {
        return self.$_.includes(roles, item)
      })
      return validRoles
    },
    fields() {
      return this.model ? this.model.attributes : []
    },
    defaultLocale() {
      return this.$_.find(this.Settings.locales, l => l.isDefault === true).locale
    },
    saveButtonVariant() {
      return this.isDirty ? 'primary' : 'outline-secondary'
    },
  },
  mounted() {},
  created() {
    const self = this
    self.loading = true
    self.model = this.$ContentTypes.findModel('system.users')
    self.fetchData()
  },
  methods: {
    async fetchData() {
      const self = this
      self.loading = true
      users.getRoles(self.projectName).then(res => {
        self.roles = res.data
        users.getUser(self.projectName, self.id).then(res => {
          self.original = res.data
          self.user = self.$_.cloneDeep(self.original)
          self.loading = false
        })
      })
    },
    onSetRoles(e) {
      this.user.roles = e
    },
    async onFormEnterKey() {
      const self = this
      if (self.isDirty && self.$acl.can('usersroles.users.update')) {
        await self.onSave()
      }
    },
    async onSave() {
      const self = this

      const validation = await self.$refs.userForm.validateWithInfo()
      if (validation.isValid) {
        users
          .updateUser(self.projectName, self.id, self.user)
          .then(res => {
            self.ResetErrors()
            self.original = { ...self.user }
            self.user = self.$_.cloneDeep(self.original)
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.HandleUnprocessableEntity(err)
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      } else {
        self.clientValidationsErrors = validation
      }
    },
    async onDelete() {
      const self = this
      users
        .deleteUser(self.projectName, self.id)
        .then(res => {
          self.serverErrors = []
          self.$bus.$emit('notification', DefaultNotifications.deleteSuccessful)
          this.$router.push({ name: 'Users', project: self.projectName })
        })
        .catch(err => {
          if (err.response.status == 422) {
            self.HandleUnprocessableEntity(err)
          } else {
            self.$bus.$emit('notification', { ...DefaultNotifications.deleteError, details: err.response.data.errors[0].message })
          }
        })
    },
    onResetChangePassword() {
      this.newPassword = {
        password: '',
        confirm: '',
      }
    },
    async onChangePassword() {
      const self = this

      const validation = await self.$refs.changePasswordForm.validateWithInfo()

      if (validation.isValid) {
        const data = {
          id: self.user.id,
          password: self.newPassword.password,
        }

        users
          .changePassword(self.projectName, self.id, data)
          .then(res => {
            self.ResetErrors()
            self.changePasswordServerErrors = []
            self.$bus.$emit('notification', DefaultNotifications.saveSuccessful)
            self.changePassword = false
          })
          .catch(err => {
            if (err.response.status == 422) {
              self.changePasswordServerErrors = err.response.data.errors
            } else {
              self.$bus.$emit('notification', { ...DefaultNotifications.saveError, details: err.response.data.errors[0].message })
            }
          })
      }
    },
    roleName(id) {
      var roles = this.roles.filter(r => r.id == id)

      if (roles && roles.length) {
        return roles[0].name
      }

      return id
    },
  },
}
</script>
